import { template as template_de736cb86d8a4b3eaaca069f488cadab } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_de736cb86d8a4b3eaaca069f488cadab(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
