import { template as template_5165deffa56f48b593ab3d37033690de } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_5165deffa56f48b593ab3d37033690de(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
