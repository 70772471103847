import { template as template_cbfd6498c0ed4a13885205d95c0f2ba1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cbfd6498c0ed4a13885205d95c0f2ba1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
