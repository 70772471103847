import { template as template_2850337ea00c4f1bb351f45befa56a0d } from "@ember/template-compiler";
const FKText = template_2850337ea00c4f1bb351f45befa56a0d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
