import { template as template_0918b3a13d334dc4af3acbc3c619de6e } from "@ember/template-compiler";
const FKLabel = template_0918b3a13d334dc4af3acbc3c619de6e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
